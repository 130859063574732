<template>
    <component :is="icon" />
</template>

<script lang="ts">
export default {
    props: {
        icon: {
            type: Object,
            required: true,
        },
    },
};
</script>
